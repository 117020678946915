<template>
    <div class="mariage">
        <div class="mariage_section">
            <h2>Cup of Love</h2>
            <div class="mariage_section_part">
                <div class="mariage_section_part_container">
                    <img src="../assets/filigrame_cup.png" alt="Logo cafe-the">
                </div>
                <h3>La base</h3>
                <p>Notre histoire a débuté de manière aussi douce qu'inattendue. Réunis lors des pauses à l'aéroport
                    d'Orly accompagnées de tasses contenant les breuvages qui nous ont rassemblé.</p>
                <br />
                <br />
                <p>C'est en repensant à ces moments que nous avons naturellement choisi le thème de notre mariage :<br /> <b><span>"Le Café et le Thé"</span></b>.</p>
            </div>
            <div class="mariage_section_part">
                <h3>Code couleur</h3>
                <img src="@/assets/ceremonies/gradient.png" alt="Gradient code couleurs" class="gradient">
                <p>Vous l'aurez compris, nous tournons notre théme autour de deux couleurs principales <b>le Marron</b> et <b>le
                    Vert</b>.</p>
                <p>Toute nuance acceptée, vous pouvez aussi, si vous le souhaitez, rester plus neutre. Le Gris étant accepté cependant nous vous prions d'éviter le Noir et le Blanc svp (Obvious?) </p>
            </div>
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="mariage_section">
            <h2>Moments Forts</h2>
            <p>De beaux moments à partager ensemble.</p>
            <div class="mariage_section_part">
                <h3>Cérémonie</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_laique',
                    source: require('../assets/ceremonies/laique.jpg'),
                    alt: 'Tour de Villemartin :)',
                    title: '14h - 15h',
                    description: 'Je dis oui'
                }" :transform="{ x: 0, y: 0, rotate: 5 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>14h00</span> dans le Jardin de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>Une promesse</h4>
                <p>C'est le moment que nous attendions avec tant d'impatience. Nous sommes extrêmement heureux de
                    partager
                    cette promesse d'amour, entourés de nos plus proches. La cérémonie laïque sera un moment de joie,
                    d'émotion et de sincérité, où chaque parole et chaque geste refléteront notre engagement mutuel.</p>
                <br />
                <p>Nous serions honorés si vous souhaitiez contribuer à ce moment en proposant un discours, une anecdote
                    ou
                    toute autre intervention qui vous tient à cœur. Votre participation rendra cette journée encore plus
                    spéciale et mémorable.</p>
            </div>
            <div class="mariage_section_part">
                <h3>Soukhouan</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_boudhiste',
                    source: require('../assets/ceremonies/baci.jpg'),
                    alt: 'Photo partie cérémonie Boudhiste',
                    title: '16h - 17h30',
                    description: 'La benediction des anciens'
                }" :transform="{ x: 0, y: 0, rotate: -7 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>16h00</span> dans la veranda de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>Une bénédiction</h4>
                <p>Après la cérémonie laïque, nous aurons le plaisir de vous inviter à partager une tradition riche et
                    profondément ancrée dans la culture de Melinda : le soukhouane laotien.</p>
                <br />
                <p>Le Soukhouan, ou "baci", est une cérémonie de bénédiction traditionnelle au Laos, symbolisant
                    l'harmonie, la prospérité et le bien-être. Cette cérémonie consiste en l'attachement de fils sacrés
                    autour des poignets, symbolisant la protection et le maintien des bonnes fortunes des participants.
                </p>
                <br />
                <p>Les fils blancs sont noués par nos proches, accompagnés de vœux de bonheur, de santé et de succès.
                    Chaque
                    nœud représente un lien de paix et d'harmonie, unissant nos âmes et nos esprits dans une célébration
                    de
                    l'amour et de la communauté.</p>
                <br />
                <p>Nous sommes ravis de pouvoir partager cette coutume précieuse avec vous !</p>
            </div>
            <div class="mariage_section_part">
                <h3>Célébration</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_soiree',
                    source: require('../assets/ceremonies/celebration.jpg'),
                    alt: 'Photo partie celebration',
                    title: '19h - 05h',
                    description: 'Il est temps de festoyer !'
                }" :transform="{ x: 0, y: 0, rotate: 5 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>19h00</span> sur la Terasse de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>La célébration</h4>
                <p>
                    Après les émotions de la cérémonie laïque et du Soukhouan laotien, il est temps de passer à la fête
                    !
                    Nous vous invitons à rejoindre la soirée où nous célébrerons notre union dans la joie et la bonne
                    humeur.
                </p>
                <br>
                <p>
                    Préparez-vous à déguster un délicieux repas, spécialement réfléchi pour cette occasion, qui saura
                    ravir
                    vos papilles. Ensuite, place à la fête : nous danserons, rirons et profiterons de chaque instant
                    ensemble.
                </p>
            </div>
        </div>
        <div class="mariage_section_signin mariage_section">
            <img src="../assets/border_top.png" alt="Border café & Thé">
            <div class="mariage_section_signin_container">
                <h2>Répondre à notre invitation</h2>
                <ButtonNavComponent text="Dire oui !" url="/sayyes"></ButtonNavComponent>
            </div>
            <img src="../assets/border_bottom.png" alt="Border café & Thé">
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="mariage_section">
            <h2>Les événements</h2>
            <p>Rien ne vaut la musique ou les jeux pour nous rassembler</p>
            <div class="mariage_section_part">
                <h3>Les Jeux</h3>
                <p>WIP - On vous en dit plus bientôt</p>
            </div>
            <div class="mariage_section_part">
                <h3>Set DJ</h3>
                <p>WIP - Soyez patient, demain, ça sera peut-être à jour</p>
            </div>
            <div class="mariage_section_part">
                <h3>Livre d'Or</h3>
                <p>WIP - Souvenirs en construction</p>
            </div>
        </div>
    </div>
</template>

<script>

import PolaroidComponent from "@/components/PolaroidComponent.vue"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ButtonNavComponent from "@/components/ButtonNavComponent.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "Le_Mariage",
    components: {
        PolaroidComponent,
        ButtonNavComponent
    },
    data() {
        return {

        }
    },
    mounted() {
        const sections = document.querySelectorAll(".mariage_section");

        sections.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });

        const parts = document.querySelectorAll(".mariage_section_part")

        parts.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });
    }
}
</script>

<style lang="scss" scoped>
.mariage {
    @include View;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
        color: $GreenShade1;
        font-size: 1em;
    }

    &_section {
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_part {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            &_container {
                img {
                    margin-bottom: 30px;
                    width: 300px;
                }
            }

            .gradient {
                width: 100%;
                max-width: 600px;
                margin: auto;
                border-radius: 15px;
                border: 0.5px solid $BrownShade1;
                margin-bottom: 25px;
            }
        }
        &_signin {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            width: 95%;
            max-width: 700px;

            &_container {
                @include Cadre;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
            }

            img {
                width: 80%;
                max-width: 500px;
            }

            h2 {
                margin: 0 0 0.5em 0;
                text-align: center;
                font-size: 2.5em;
            }
        }

        h3 {
            margin: 25px;
        }

        h4 {
            margin: 25px auto;
        }
    }
}
</style>