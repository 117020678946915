<template>
    <div class="notabene">
        <div class="notabene_section" id="section1">
            <h2>Nous joindre</h2>
            <div class="notabene_section_contact">
                <div class="notabene_section_contact_part">
                    <img class="portrait" src="@/assets/melinda_portrait.png" alt="Portrait de mélinda">
                    <div class="notabene_section_contact_part_comment">
                        <font-awesome-icon icon="phone" class="icone" />
                        <p>: 06.59.93.59.85</p>
                    </div>
                    <div class="notabene_section_contact_part_comment">
                        <font-awesome-icon icon="envelope" class="icone" />
                        <p>: <a href="mailto:sayasith.melinde@gmail.com">sayasith.melinde@gmail.com</a></p>
                    </div>
                </div>
                <div class="notabene_section_contact_part">
                    <img class="portrait" src="@/assets/jeremy_portrait.png" alt="Portrait de jeremy">
                    <div class="notabene_section_contact_part_comment">
                        <font-awesome-icon icon="phone" class="icone" />
                        <p>: 06.38.36.94.14</p>
                    </div>
                    <div class="notabene_section_contact_part_comment">
                        <font-awesome-icon icon="envelope" class="icone" />
                        <p>: <a href="mailto:jeremy.tardini@gmail.com">jeremy.tardini@gmail.com</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="notabene_section" id="section2">
            <h2>Nous rejoindre</h2>
            <p>Vous vous posez des question sur comment nous rejoindre et le temps que cela peut prendre ? Toutes les
                informations sont plus bas</p>
            <button id="walker" @click="Determinate">
                <img src="@/assets/notabene/walker.png" alt="A simple walker">
            </button>
            <div class="notabene_section_part">
                <h3 class="plan_h3">Plan</h3>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21165.839831728805!2d2.1733081!3d48.4617102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5cecdb4b32989%3A0x7ebe235e0b4fdff4!2s21%20All.%20des%20Marronniers%2C%2091150%20Morigny-Champigny!5e0!3m2!1sfr!2sfr!4v1721471454737!5m2!1sfr!2sfr"
                    allowfullscreen="" loading="lazy" class="notabene_section_part_map"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class="notabene_section_part_cadre cadre">
                    <img src="@/assets/border_top.png" alt="Border café & Thé">
                    <div class="notabene_section_part_cadre_focus">
                        <p class="adresse">RDV 21 All. des Marronniers, 91150 Morigny-Champigny</p>
                        <div class="notabene_section_part_cadre_focus_navigate">
                            <button @click="GoToWaze">
                                <img src="@/assets/notabene/waze_logo.png" alt="Navigation vers waze">
                            </button>
                            <button @click="GoToMaps">
                                <img src="@/assets/notabene/google-maps.jpeg" alt="Navigation vers Google maps">
                            </button>
                        </div>
                    </div>
                    <img src="@/assets/border_bottom.png" alt="Border café & Thé">
                </div>
            </div>
            <div class="notabene_section_part">
                <h3 class="parking_h3">Parking</h3>
                <img src="@/assets/notabene/plan_parking.png" alt="Plan pour accéder au parking" class="park_map">
                <p>Le parking va de l'entrée de l'hôtellerie, jusqu'au bâtiment principal jusqu'à son extérieur en
                    longeant le bord de route. Pour nos chers invités
                    qui ne trouveraient malheureusement de place, sachez qu'il y en a en ville.
                </p>
            </div>
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="notabene_section" id="section3">
            <h2>Hebergement</h2>
            <p>Nous n'avons, à notre grand regret pas de solution simple et gratuite à vous proposer afin de vous loger
                après cette journée. Cependant, nous avons d'autres possibilités à vous proposer.</p>
            <div class="notabene_section_part">
                <h3>Rester avec nous</h3>
                <p>Actuellement l'Hôtellerie de Villemartin propose une dizaine de chambres avec pour coup <span>un total
                        de 100€ par chambre</span>.</p>
                <div class="notabene_section_part_cadre">
                    <img src="../assets/border_top.png" alt="Border café & Thé">
                    <div class="notabene_section_part_cadre_stay">
                        <h4>Vous souhaitez avoir une chambre ?</h4>
                        <ButtonNavComponent text="Dites le nous" url="/sayyes"></ButtonNavComponent>
                    </div>
                    <img src="../assets/border_bottom.png" alt="Border café & Thé">
                </div>
            </div>
            <div class="notabene_section_part">
                <h3>Vous loger ailleurs ?</h3>
                <p>Concernant les possibilités de logements supplémentaires, voici un petit raccourci vers notre
                    application de location (préférée ?) ..</p>
                <div class="notabene_section_part_navigate">
                    <button @click="GotoAirBnb">
                        <img src="@/assets/notabene/logo_airbnb.png" alt="Recherche Air BNB">
                    </button>
                </div>
            </div>
            <div class="notabene_section_part">
                <h3>Une vie de baroude ...</h3>
                <p>Nous avons plein d'amis et proches qui auront sûrement des solutions pour vous. Un petit coup de fil
                    et on trouvera une solution ensemble.</p>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonNavComponent from '@/components/ButtonNavComponent.vue';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    name: "Nota_bene",
    components: {
        ButtonNavComponent
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger);

        // Animations générales
        gsap.from(".notabene_section h2", {
            opacity: 0,
            y: 50,
            duration: 1,
            stagger: 0.2
        });

        // Animation pour les portraits
        gsap.from(".notabene_section_contact_part img", {
            opacity: 0,
            y: 50,
            rotate: 20,
            duration: 1,
            stagger: 0.3,
            ease: "back.out(1.7)"
        });

        // Animations pour les sections avec ScrollTrigger
        const sections = document.querySelectorAll(".notabene_section_part");

        sections.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });
    },
    methods: {
        GoToWaze() {
            const wazeLink = "https://ul.waze.com/ul?place=ChIJiSmztM3O5UcR9N9PC14jvn4&ll=48.46147900%2C2.18755600&navigate=yes";
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                // Android device
                window.location.href = "waze://?ll=48.46147900,2.18755600&navigate=yes";
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                // iOS device
                window.location.href = "waze://?ll=48.46147900,2.18755600&navigate=yes";
            } else {
                // Fallback to web link
                window.open(wazeLink, "_blank");
            }
        },
        GoToMaps() {
            const googleMapsLink = "https://www.google.fr/maps/place/21+All.+des+Marronniers,+91150+Morigny-Champigny/@48.4617102,2.1733081,14z/data=!4m6!3m5!1s0x47e5cecdb4b32989:0x7ebe235e0b4fdff4!8m2!3d48.461479!4d2.187556!16s%2Fg%2F11pvb1t8c5?entry=ttu";
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                // Android device
                window.location.href = "geo:0,0?q=21+All.+des+Marronniers,+91150+Morigny-Champigny";
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                // iOS device
                window.location.href = "comgooglemaps://?q=21+All.+des+Marronniers,+91150+Morigny-Champigny";
            } else {
                // Fallback to web link
                window.open(googleMapsLink, "_blank");
            }
        },
        GotoAirBnb() {
            const url = "https://www.airbnb.fr/s/Morigny~Champigny--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-08-01&monthly_length=3&monthly_end_date=2024-11-01&price_filter_input_type=0&channel=EXPLORE&query=Morigny-Champigny%2C%20France&place_id=ChIJ095kNd7I5UcRoEOLaMOCCwQ&date_picker_type=calendar&checkin=2025-04-25&checkout=2025-04-27&source=structured_search_input_header&search_type=user_map_move&search_mode=regular_search&price_filter_num_nights=2&ne_lat=48.46376596754442&ne_lng=2.1992333770648997&sw_lat=48.40964627712774&sw_lng=2.1292000905745567&zoom=13.601464094112076&zoom_level=13.601464094112076&search_by_map=true"
            window.open(url, '_blank');
        },
        Determinate() {
            const destination = '21+All.+des+Marronniers,+91150+Morigny-Champigny';
            const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}&travelmode=walking`;
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.notabene {
    @include View;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_section {
        width: 100%;

        &_contact {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            &_part {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
                max-width: 500px;
                min-width: 250px;

                &_comment {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-left: 15px;
                    }

                    a {
                        font-size: 1em;
                    }

                    .icone {
                        color: $GreenShade1
                    }
                }

                img {
                    width: 200px;
                    height: 200px;
                    border-radius: 100%;
                    margin: 10px;

                }
            }
        }

        &_part {

            width: 100%;

            &_map {
                width: 80vw;
                height: 300px;
                border-radius: 15px;
                box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
                border: 0.5px groove $BrownShade3;
                max-width: 1000px;
            }

            &_cadre {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &_stay {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    @include Cadre;

                    h4 {
                        font-size: 1.2em;
                        margin: 10px auto;
                    }

                    button {
                        background-color: $GreenShade1;
                        border-radius: 15px;
                        border: 1px groove $GreenShade2 ;
                        width: 70%;
                        max-width: 250px;
                        padding: 15px;
                        box-shadow: 0 0.2rem 1.2rem rgba(1, 1, 10, 0.2);
                        font-family: "Sarlotte";
                        color: $BrownShade1;
                        font-size: 1.5em;
                    }
                }

                &_focus {

                    @include Cadre;

                    &_navigate {

                        width: 100%;
                        display: flex;
                        /* flex-direction: column; */
                        align-items: center;
                        justify-content: space-evenly;
                        margin: 1em auto;

                        button {
                            @include ButtonNavigate;
                        }
                    }
                }



                img {
                    width: 80%;
                    max-width: 500px;
                }
            }

            &_navigate {
                button {
                    @include ButtonNavigate;
                }
            }

            .portrait {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 100%;
            }

            .park_map {
                width: 90%;
                max-width: 650px;
                object-fit: cover;
                border-radius: 15px;
                box-shadow: 0 0.2rem 1.2rem rgba(1, 1, 10, 0.2);
                border: 0.5px groove $BrownShade3;

            }

            .adresse {
                color: $GreenShade1;
                padding-top: 1em;
                font-weight: 800;
            }
        }

        span {
            color: $GreenShade1;
        }

        h3 {
            margin: 30px auto;
        }

        #walker {
            background-color: transparent;
            border: none;
            margin: 15px;
            cursor: pointer;

            img {
                border: none;
                height: 50px;
                width: 50px;
                object-fit: cover;
                box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0);

            }
        }
    }

    h2 {
        margin: 45px auto;
    }

    a {
        color: black
    }
}
</style>